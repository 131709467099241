.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 70vw;
  max-width: 600px; /* Adjust the maximum width as needed */
  max-height: 658px; /* Adjust the maximum height as needed */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: -2px 2px 8px 2px #819EF0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
}


.modal-content {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content button {
  margin-top: 16px;
}

.modal-content .ReactCrop {
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
  box-shadow: -2px 2px 8px 2px #819ef0;
  cursor: move;
}