.gradient-animation {
  height: 100%;
  background: linear-gradient(135deg, var(--color-1), var(--color-2), var(--color-3)); /* Use CSS variables for gradient colors */
  background-size: 400% 400%;
  animation: gradient-animation 15s ease infinite;
  border: 1px solid #fff;
  --color-1: #6772e5;
  --color-2: #a77ef5;
  --color-3: #fc7d7d;
  --color-4: #f9b065;
  --color-5: #facf5a;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% var(--bg-y, 50%); /* Use CSS variable for background position */
  }
  50% {
    background-position: 100% var(--bg-y, 50%); /* Move the background to the end of the gradient halfway through the animation */
  }
  100% {
    background-position: 0% var(--bg-y, 50%); /* Move the background back to the starting point at the end of the animation */
  }
}